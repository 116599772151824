import React, { useMemo } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    innerWrapper,
    logo,
    topContent,
    title,
    bottomContent,
    regulationTitle,
    regulationContent,
    regulationLink,
    image,
    leftTop,
    leftBottom,
    rightTop,
    rightBottom,
} from './wedding-contest-closed-info.module.scss';

import Title from '../atoms/title';
import staticFiles from '../../config/static-files';

const leftTopImage = '../../assets/images/wedding-contest/left-top-full.png';
const rightTopImage = '../../assets/images/wedding-contest/right-top-full.png';
const rightBottomImage = '../../assets/images/wedding-contest/right-bottom.png';
const leftBottomImage = '../../assets/images/wedding-contest/left-bottom.png';
const logoSrc = '../../assets/images/logo.png';

export interface IWeddingContestClosedInfoProps {
    className?: string;
}

export default function WeddingContestClosedInfo({ className }: IWeddingContestClosedInfoProps) {
    const { t, i18n, language } = useI18next();

    const regulationTranslations = useMemo(() => {
        return i18n.getResource(language, 'translation', 'wedding.contest.footer.regulations');
    }, [i18n, language]);

    return (
        <div className={[container, className].join(' ')}>
            <div className={innerWrapper}>
                <div className={[image, leftTop].join(' ')}>
                    <StaticImage
                        src={leftTopImage}
                        alt={t('wedding.contest.winner.form.classic.decoration')}
                        placeholder={'blurred'}
                    />
                </div>
                <div className={[image, leftBottom].join(' ')}>
                    <StaticImage
                        src={leftBottomImage}
                        alt={t('wedding.contest.winner.form.boho.decoration')}
                        placeholder={'blurred'}
                    />
                </div>
                <StaticImage
                    src={logoSrc}
                    alt={t('logo.alt')}
                    placeholder={'none'}
                    width={107}
                    className={logo}
                />
                <div className={topContent}>
                    <Title className={title}>{t('wedding.contest.closed.title')}</Title>
                    <Title Tag="p" isSmall>
                        {t('wedding.contest.closed.description.1')}
                    </Title>
                    <Title Tag="p" isSmall>
                        {t('wedding.contest.closed.description.2')}
                    </Title>
                </div>
                <div className={bottomContent}>
                    <Title Tag="h3" isSmall isUppercase className={regulationTitle}>
                        {t('contact.info.regulations.header')}
                    </Title>
                    <p className={regulationContent}>
                        {regulationTranslations[0]}{' '}
                        <a
                            href={staticFiles.weddingContestRegulations}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={regulationLink}
                        >
                            {regulationTranslations[1]}
                        </a>{' '}
                        {regulationTranslations[2]}
                    </p>
                </div>
                <div className={[image, rightTop].join(' ')}>
                    <StaticImage
                        src={rightTopImage}
                        alt={t('wedding.contest.winner.form.classic.decoration')}
                        placeholder={'blurred'}
                    />
                </div>
                <div className={[image, rightBottom].join(' ')}>
                    <StaticImage
                        src={rightBottomImage}
                        alt={t('wedding.contest.winner.form.boho.decoration')}
                        placeholder={'blurred'}
                    />
                </div>
            </div>
        </div>
    );
}
