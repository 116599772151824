import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, header, slider, noSlide } from './recipe-slider.module.scss';

import { IRecipe } from '../../models/recipe.model';

import RecipeCard from '../molecules/recipe-card';
import AngleButton from '../atoms/angle-button';
import BlockHeader from '../molecules/block-header';
import Slider from '../hoc/slider';
import ListingLink from '../atoms/listing-link';

interface IRecipeSlider {
    className?: string;
    slides: IRecipe[];
    titleText: string;
    subtitleText?: string;
    showSummary?: boolean;
}

const RecipeSlider: React.FC<IRecipeSlider> = ({
    className = '',
    slides,
    titleText,
    subtitleText,
    showSummary = true,
}) => {
    const { t } = useI18next();
    return (
        <div className={[container, className].join(' ')}>
            <BlockHeader
                mobileHideSubtitle={false}
                className={header}
                titleText={titleText}
                subtitleText={subtitleText}
            />
            <Slider
                ButtonComponent={AngleButton}
                showNav="desktop"
                forceArrowSpace={true}
                className={slider}
                noSliderItemClass={noSlide}
                breakpoints={{
                    0: { slidesPerView: 1 },
                    600: { slidesPerView: 2 },
                    1025: { slidesPerView: 3 },
                    1392: { slidesPerView: 4 },
                }}
            >
                {slides.map((recipe, index) => {
                    return (
                        <RecipeCard
                            type="slider"
                            key={`recipe_slide_${index}`}
                            data={recipe}
                            fullWidth={false}
                            showSummary={showSummary}
                        />
                    );
                })}
            </Slider>
            <ListingLink to="drinks" text={t('recipe.page.slider.link')} />
        </div>
    );
};

export default RecipeSlider;
