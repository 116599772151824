import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import { link } from './listing-link.module.scss';

interface IListingLink {
    className?: string;
    to: string;
    text: string;
}

const ListingLink: React.FC<IListingLink> = ({ className = '', to, text }) => {
    return (
        <Link className={`${link} ${className}`} to={to}>
            {text}
        </Link>
    );
};

export default ListingLink;
