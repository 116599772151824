// extracted by mini-css-extract-plugin
export var backgroundAnimation = "wedding-contest-closed-info-module--backgroundAnimation--24aaa";
export var bottomContent = "wedding-contest-closed-info-module--bottom-content--2c2d2";
export var container = "wedding-contest-closed-info-module--container--d7ef9";
export var errorBlink = "wedding-contest-closed-info-module--error-blink--d8ac3";
export var image = "wedding-contest-closed-info-module--image--e1686";
export var innerWrapper = "wedding-contest-closed-info-module--inner-wrapper--82f70";
export var leftBottom = "wedding-contest-closed-info-module--left-bottom--e6356";
export var leftTop = "wedding-contest-closed-info-module--left-top--169a1";
export var logo = "wedding-contest-closed-info-module--logo--825a8";
export var regulationContent = "wedding-contest-closed-info-module--regulation-content--028e7";
export var regulationLink = "wedding-contest-closed-info-module--regulation-link--a3e0d";
export var regulationTitle = "wedding-contest-closed-info-module--regulation-title--38560";
export var rightBottom = "wedding-contest-closed-info-module--right-bottom--b1c9b";
export var rightTop = "wedding-contest-closed-info-module--right-top--79476";
export var title = "wedding-contest-closed-info-module--title--81711";
export var topContent = "wedding-contest-closed-info-module--top-content--fe2ed";