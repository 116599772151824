import React from 'react';
import { graphql } from 'gatsby';

import { layout, slider, info } from './closed.module.scss';
import { IPage } from '../../../models/page.model';
import { IRecipe } from '../../../models/recipe.model';
import { IQueryAllResult } from '../../../models/query-all-result.model';
import { getNodes } from '../../../utils/get-nodes';

import MainLayout from '../../../layouts/main-layout';
import RecipeSlider from '../../../components/organisms/recipe-slider';
import WeddingContestClosedInfo from '../../../components/organisms/wedding-contest-closed-info';

export interface IWeddingContestClosedProps {
    readonly data: {
        page: IPage | null;
        promotedRecipes: IQueryAllResult<IRecipe>;
    };
}

export default function Closed({ data }: IWeddingContestClosedProps) {
    const { page, promotedRecipes } = data;

    const recipes = getNodes(promotedRecipes);

    return (
        <MainLayout className={layout} page={page}>
            <WeddingContestClosedInfo className={info} />
            <RecipeSlider slides={recipes} titleText="Odkryj nasze przepisy" className={slider} />
        </MainLayout>
    );
}

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }

        promotedRecipes: allStrapiRecipe(
            filter: { isPromoted: { eq: true }, locale: { eq: $language } }
        ) {
            edges {
                node {
                    ...recipeCardFields
                }
            }
        }
    }
`;
